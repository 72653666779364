// Mixins
import uiMixin from '@/mixins/uiMixin'

export default {
  name: 'VuetifyToolBar',
  mixins: [uiMixin],
  props: {
    title: {
      default: 'Title',
      type: String
    },
    hideTitle: {
      default: false,
      type: Boolean
    },
    hideBackButton: {
      default: false,
      type: Boolean
    },
    hideActionButton: {
      default: true,
      type: Boolean
    },
    buttonVOptions: {
      default() {
        return {}
      },
      type: Object
    },
    sticky: {
      default: false,
      type: Boolean
    },
    /**
     * https://vuetifyjs.com/en/components/toolbars/#toolbars
     */
    toolBarVOptions: {
      default() {
        return {
          dense: true,
          flat: true
        }
      },
      type: Object
    }
  },
  computed: {
    /**
     * Estilos del botón personalizados
     *
     * @return {Object}
     */
    customButtonVOptions() {
      return { icon: true, elevation: 0, ...this.buttonVOptions }
    },
    /**
     * Estilos de la barra personalizado
     *
     * @return {Object}
     */
    customToolBarVOptions() {
      return { ...this.toolBarVOptions }
    }
  },
  methods: {
    /**
     * Handle Back Button
     */
    handleClickToolBarBackButton() {
      this.routerGoTo(-1)
    },
    /**
     * Handle Action Button
     */
    handleClickToolBarActionButton() {
      this.$emit('onClickToolBarActionButton')
    }
  }
}
