// Request
import { doRequest } from '@/utils/requestApi'
// utils
const fileDownload = require('js-file-download')
import { get, isNil } from 'lodash'

/**
 * Create nueva company activations
 *
 * @param {Object} data - company activation data
 */
export async function createCompanyActivation(data, id = null) {
  const result = await doRequest({
    method: 'post',
    url: isNil(id) ? 'v1/company-activation/create' : `v1/company-activation/create/${id}`,
    data: {
      data
    }
  })

  return result.data
}

/**
 * Eliminamos "activación" de compañía
 *
 * @param {string} id - UID de "companyActivation"
 */
export async function deleteCompanyActivationById(id) {
  const result = await doRequest({
    method: 'delete',
    url: `v1/company-activation/${id}`
  })

  return result.data
}

/**
 * Obtenemos "activación" de compañía
 *
 * @param {string} id - UID category in database
 */
export async function getCompanyActivationById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/company-activation/${id}`
  })

  return result.data
}

/**
 * Obtenemos todas las activaciones aplicando filtros de búsqueda
 *
 * @param {object} options - options to get data
 */
export async function getEveryCompanyActivations(options = {}) {
  const formatIsCSV = get(options, 'format', null) === 'csv'

  const result = await doRequest({
    method: 'post',
    url: 'v1/company-activation',
    data: {
      ...options
    },
    ...(formatIsCSV
      ? {
          timeout: 20000,
          responseType: 'blob'
        }
      : {})
  })

  if (formatIsCSV) {
    return fileDownload(result.data, `companyActivations_${Date.now().toString()}.csv`)
  } else {
    return result.data
  }
}

/**
 * Actualiamos "activación" de compañía
 *
 * @param {Object} data - datos de la activación data
 */
export async function updateCompanyActivationById(data) {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/company-activation/${id}`,
    data: {
      data: params
    }
  })

  return result.data
}
