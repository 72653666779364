// Componentes
import ActivationFormEdit from '../ActivationFormEdit'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Filters
import { activationStatusLabel, activationTypesLabel, formatDate } from '@/filters'

export default {
  name: 'ActivationsListItem',
  props: {
    items: {
      required: true,
      type: Array,
      default() {
        return []
      }
    },
    loading: {
      required: true,
      type: Boolean,
      default: true
    }
  },
  filters: { activationStatusLabel, activationTypesLabel, formatDate },
  mixins: [uiMixin],
  methods: {
    /**
     * Cuando pulsamos sobre el botón de detalle
     *
     * @param {string} id - UID del establecimiento
     */
    handleClickDetail(id) {
      this.modifyAppDialog({
        title: 'Editar activación',
        contentComponent: ActivationFormEdit,
        contentComponentProps: {
          id
        },
        hideActionButtons: true,
        visible: true
      })
    }
  }
}
