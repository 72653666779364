// Request
import { doRequest } from '@/utils/requestApi'
// utils
const fileDownload = require('js-file-download')
import { get, isNil } from 'lodash'

/**
 * Obtenemos los datos de las estadísticas del establecimiento
 *
 * @param {string} id - UID establecimiento
 * @param {string} fromDate - Fecha desde
 * @param {string} fromTo - Fecha hasta
 * @param {boolean} compareIntervals - Realizamos búsqueda comparando intervalo anterior
 */
export const getAnalyticsDataByPlaceId = async (id, fromDate, toDate, compareIntervals = true) => {
  const result = await doRequest({
    method: 'post',
    url: `v1/place/analytics-data/${id}`,
    data: {
      fromDate,
      toDate,
      compareIntervals
    }
  })

  // Parsemos los datos antes de devolverlos
  const data = get(result, 'data', null)
  const visits = get(result, 'data.visits', null)

  if (isNil(data) || isNil(visits)) {
    return data
  }
  // Eliminamos array de visitas (dentro del objeto)
  delete data.visits

  // Devolvemos el objeto ya completado con sus datos
  return {
    ...visits.reduce((sumVisits, visit) => {
      const key = visit.link.indexOf(id) > -1 ? 'totalVisitsQR' : 'totalVisitsURL'
      sumVisits[key] = visit.value
      sumVisits[`${key}IncrementPeriods`] = visit.increment
      return sumVisits
    }, {}),
    ...data
  }
}

/**
 * Obtenemos los datos de las ventas del establecimiento (Chart)
 *
 * @param {string} id - UID establecimiento
 * @param {number} daysAgo - Números de días a aplicar consulta
 */
export const getOrdersDataForDashboardByPlaceId = async (id, daysAgo) => {
  const result = await doRequest({
    method: 'post',
    url: `v1/place/orders-data-dashboard/${id}`,
    data: {
      daysAgo
    }
  })

  return result.data
}

/**
 * Get place by UID
 *
 * @param {string} id - UID place in database
 * @param {object} options - options to get data
 */
export async function getPlaceById(
  id,
  options = { includeAddOnsConfigs: false, includeSubscriptions: false }
) {
  const result = await doRequest({
    method: 'post',
    url: `v1/place/${id}`,
    data: {
      options
    }
  })

  return result.data
}

/**
 * Obtenemos establecimiento mediante su "companyActivationId"
 *
 * @param {string} id - "companyActivationId" place in database
 */
export async function getPlaceByCompanyActivationId(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/place/company-activation/${id}`
  })

  return result.data
}

/**
 * Get every places by options
 *
 * @param {object} options - options to get data
 */
export async function getEveryPlaces(options = {}) {
  const formatIsCSV = get(options, 'format', null) === 'csv'

  const result = await doRequest({
    method: 'post',
    url: 'v1/place',
    data: {
      ...options
    },
    ...(formatIsCSV
      ? {
          timeout: 20000,
          responseType: 'blob'
        }
      : {})
  })

  if (formatIsCSV) {
    return fileDownload(result.data, `places_${Date.now().toString()}.csv`)
  } else {
    return result.data
  }
}
