export default {
  name: 'VuetifyDataTable',
  props: {
    headers: {
      type: Array,
      default() {
        return []
      }
    },
    /**
     * https://vuetifyjs.com/en/components/data-tables/#data-tables
     */
    dataTableVOptions: {
      type: Object,
      default() {
        return {}
      }
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    itemsPerPage: {
      type: Number,
      default: 15
    },
    loading: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      default: 1
    },
    search: {
      type: String,
      default: ''
    }
  },
  computed: {
    /**
     * Return options to v-data-table
     * https://vuetifyjs.com/en/components/data-tables/#data-tables
     */
    currentDataTableVOptions() {
      return {
        'items-per-page': this.itemsPerPage,
        'loading-text': 'Cargando elementos...',
        ...this.dataTableVOptions
      }
    },
    /**
     * Get headers data depending of "items" and "headers"
     *
     * La idea de esta función es auto-generar las cabeceras ("headers")
     * que necesita la tabla mediante el atributo "items", indicando
     * que cabeceras deseamos mostrar y cuales no.
     */
    currentHeadersData() {
      const customHeaders = this.headers
      const areThereCustomHeaders = Array.isArray(customHeaders) && customHeaders.length > 0
      let parsedHeaderData = []

      if (this.items && this.items.length) {
        const itemData = this.items[0]

        if (typeof itemData === 'object' && itemData !== null) {
          // Incluyo, en el array a devolver los items que existen en
          // "customHeaders" pero que no existen en "itemData"
          // ("value" no tiene atributo )
          parsedHeaderData = customHeaders.filter((item) => !item.value)
          // Ahora emparejamos los datos de "items" con los datos de "customHeaders"
          parsedHeaderData = Object.keys(itemData).reduce((sumHeaderDataKey, headerDataKey) => {
            // Valores por defecto tomados de "items"
            const currentHeaderData = {
              text: headerDataKey,
              value: headerDataKey
            }

            if (areThereCustomHeaders) {
              const headerDataIndex = customHeaders.findIndex((item) => {
                return item.value === headerDataKey
              })

              if (headerDataIndex > -1) {
                sumHeaderDataKey.push({
                  ...currentHeaderData,
                  ...customHeaders[headerDataIndex]
                })
              }
            } else {
              sumHeaderDataKey.push(currentHeaderData)
            }

            return sumHeaderDataKey
          }, parsedHeaderData)
        }
        // Debido al parseo, el orden de los objetos dentro del array
        // cambia según lo indicado por el usuario y debemos reordenarlos
        // como el usuario indica
        if (areThereCustomHeaders) {
          parsedHeaderData.sort((a, b) => {
            const aIndex = customHeaders.findIndex((item) => item.value === a.value)
            const bIndex = customHeaders.findIndex((item) => item.value === b.value)
            return aIndex - bIndex
          })
        }
      } else {
        parsedHeaderData = this.headers
      }

      return parsedHeaderData
    }
  }
}
