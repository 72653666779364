// Google Maps
import { addressFormat } from '@/services/googleMap/config'
import { loadGoogleMap } from '@/services/googleMap'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Filters
import { formatGoogleAddressObject } from '@/filters'
// Utils
import { isNil } from 'lodash'

export default {
  name: 'GoogleMapAutocomplete',
  mixins: [uiMixin],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    /**
     * Restricciones para las búsquedas
     * @link https://developers.google.com/maps/documentation/javascript/examples/geocoding-component-restriction
     */
    componentRestrictions: {
      type: Object,
      default() {
        return {}
      }
    },
    color: {
      type: String,
      default: 'grey'
    },
    dense: {
      type: Boolean,
      default: true
    },
    errorMessages: {
      type: Array,
      default() {
        return []
      }
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: 'Dirección a buscar'
    },
    prependIcon: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'text'
    },
    /**
     * Tipo de direcciónes que deseamos buscar (GoogleMaps)
     */
    types: {
      type: Array,
      default() {
        return ['address']
      }
    },
    value: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      /**
       * The Autocomplete object.
       * @link https://developers.google.com/maps/documentation/javascript/reference#Autocomplete
       */
      autocomplete: null,
      google: null
    }
  },
  computed: {
    /**
     * Mostramos/ocultamos los "detalles" del ca,
     *
     * @return {Boolean}
     */
    hideFieldDetails() {
      return this.hideDetails && this.errorMessages.length === 0
    },
    /**
     * Objeto de "place" formateado para mostrar en el INPUT
     */
    parsedValue() {
      return formatGoogleAddressObject(this.value)
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Establece/obtiene todos los valores necesarios
     * para el componente inicialmente
     */
    async getEveryNeededData() {
      try {
        // Load Google Maps API
        this.google = await loadGoogleMap()
        // Cargamos auto-complete
        this.loadAutocomplete()
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      }
    },
    /**
     * Load auto-complete component
     */
    loadAutocomplete() {
      const options = {
        types: this.types
      }

      if (isNil(this.componentRestrictions)) {
        options.componentRestrictions = this.componentRestrictions || {}
      }

      // Si ya cargo el elemento HTML
      if (this.$refs.autocomplete) {
        this.autocomplete = new this.google.maps.places.Autocomplete(
          this.$refs.autocomplete.$refs.input,
          options
        )

        this.autocomplete.addListener('place_changed', this.handlePlaceChanged)
      }
    },
    /**
     * Cuando el usuario pulsa la tecla de borrado o retroceso
     *
     * @param {event}
     */
    handleKeyUpDelete(event) {
      // Tecla de "retroceso" o "borrado"
      if (event.keyCode === 8 || (event.keyCode === 46 && !isNil(this.value))) {
        this.$emit('change', null)
      }
    },
    /**
     * When a place changed
     */
    handlePlaceChanged() {
      const place = this.autocomplete.getPlace()

      if (isNil(place.geometry)) {
        this.$emit('change', null)
      } else if (!isNil(place.address_components)) {
        this.$emit('change', this.parseResult(place))
      }
    },
    /**
     * Format result from Geo google APIs
     *
     * @param {Object} place
     * @return {{formatted output}}
     */
    parseResult(place) {
      const returnData = Object.entries(addressFormat).reduce((sumFields, field) => {
        const addressComponents = place.address_components.find((address) => {
          return address.types[0] === field[1].id
        })

        if (addressComponents) {
          sumFields[field[0]] = addressComponents[field[1].value]
        }

        return sumFields
      }, {})

      // Incluimos las coordenadas
      returnData.latitude = place.geometry.location.lat()
      returnData.longitude = place.geometry.location.lng()

      return returnData
    }
  }
}
