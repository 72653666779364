// Mixins
import uiMixin from '@/mixins/uiMixin'
// Utils
import { isNil } from 'lodash'

export default {
  name: 'ListImagesPreview',
  mixins: [uiMixin],
  props: {
    /**
     * Botones de acción
     */
    hideShowButton: {
      type: Boolean,
      default: false
    },
    hideRemoveButton: {
      type: Boolean,
      default: false
    },
    /**
     * Images to show in the list
     */
    images: {
      required: true,
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    /**
     * Evento lanzado tras pulsar en el botón de "Mostrar imagen"
     *
     * @param {number} index - indice de la imagen dentro del listado
     */
    async handleShowButton(index) {
      try {
        if (isNil(this.images[index])) {
          throw new Error('No existe la imagen indicada')
        }

        // Abrir imagen en pestaña independiente
        window.open(this.images[index], '_blank')
        // Emitimos evento
        this.$emit('onShowImage', index)
      } catch (error) {
        this.handleError(error.message)
      }
    },
    /**
     * Evento lanzado tras pulsar en el botón de "Borrar imagen"
     *
     * @param {number} index - indice de la imagen dentro del listado
     */
    async handleRemoveButton(index) {
      try {
        if (isNil(this.images[index])) {
          throw new Error('No existe la imagen indicada')
        }
        // Emitimos evento
        this.$emit('onRemoveImage', index)
      } catch (error) {
        this.handleError(error.message)
      }
    },
    /**
     * Get the files that the user wants to upload
     *
     * @param {string} error - error to show
     */
    handleError(error) {
      this.modifyAppAlert({
        type: 'error',
        text: error,
        visible: true
      })
    }
  }
}
