// Constantes
import { ACTIVATION_TYPES, ACTIVATION_STATUS } from '@/constants'
// Components
import CameraUploader from '@/components/ui/CameraUploader'
import FormButtons from '@/components/ui/FormButtons'
import GoogleMapAutocomplete from '@/components/ui/GoogleMapAutocomplete'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Services
import {
  updateCompanyActivationById,
  getCompanyActivationById,
  deleteCompanyActivationById
} from '@/services/companyActivation'
import { getPlaceByCompanyActivationId } from '@/services/place'
// Utils
import { get, isNil } from 'lodash'
import { getEnvironmentVariable } from '@/utils'

export default {
  name: 'ActivationFormEdit',
  components: { CameraUploader, FormButtons, GoogleMapAutocomplete, VuetifyContentLoading },
  mixins: [formMixin, uiMixin],
  props: {
    id: {
      required: true,
      type: String,
      default: null
    }
  },
  data() {
    return {
      // Form
      formFields: {},
      // Others
      activationStatus: Object.values(ACTIVATION_STATUS),
      activationTypes: Object.values(ACTIVATION_TYPES),
      fieldLabels: {
        email: 'Email',
        images: 'Imágenes',
        name: 'Nombre',
        phone: 'Teléfono',
        place: 'Dirección',
        placeName: 'Nombre del establecimiento',
        placeUrl: 'Url del establecimiento',
        type: 'Tipo',
        status: 'Estado'
      },
      placeId: null, // Id del establecimiento asociado a la activación
      processingRequest: true
    }
  },
  computed: {
    /**
     * Convertimos el objeto "formFields" en un array
     * para así ordenar los campos alfabéticamente
     *
     * @returns {array}
     */
    formFieldsToArray() {
      return Object.entries(this.formFields)
        .reduce((accFormFields, field) => {
          accFormFields.push({
            id: field[0],
            value: field[1]
          })
          return accFormFields
        }, [])
        .sort((a, b) => a.id.localeCompare(b.id, 'es'))
    },
    /**
     * Definimos en que ruta se almacenará las imágenes
     *
     * @return {string}
     */
    pathToSaveImg() {
      return `activations/${getEnvironmentVariable('VUE_APP_BRAND')}`
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * When the user must click on cancel button
     */
    handleCancelButton() {
      this.hideDialog()
    },
    /**
     * Cuando pulsamos sobre el botón de "Eliminar"
     */
    async handleDeleteButton() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          // Removing item
          await this.deleteCompanyActivation()
          // Hide dialog
          this.hideDialog()
          // Emitimos evento general para que cualquier lista
          // a la escucha, recargue su listado
          this.$root.$emit('reload-list')
        },
        actionButtonText: 'Borrar',
        text: '¿Desea borrar la activación?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Tratamos las imágenes subidas
     * @param {array} images - imágenes subidas en el componente
     */
    handleImages(images) {
      this.formFields.images = images
    },
    /**
     * Inicializamos/obtenemos todos los datos necesarios
     * del componente
     */
    async getEveryNeededData() {
      try {
        if (isNil(this.id)) {
          throw new Error('No se ha indicado ningún identificador de activación.')
        }
        // Loading
        this.processingRequest = true
        // Obtenemos datos de la activación
        const companyActivation = await getCompanyActivationById(this.id)
        // Establecemos valores de los campos del formulario
        this.setFormFieldsValues(companyActivation)
        // Obtenemos datos del posible establecimiento relacionado a la activación
        const place = await this.getPlaceByCompanyActivationId(this.id)
        this.placeId = isNil(place) ? null : place.id
      } catch (error) {
        // show error
        this.handleError(error.message)
        this.hideDialog()
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Establecemos los valores iniciales de los
     * campos del formulario
     *
     * @param {object} data - objeto con los datos a establecer
     */
    setFormFieldsValues(data = {}) {
      // "Eliminamos" campos no editables
      const {
        // eslint-disable-next-line no-unused-vars
        brand = null,
        // eslint-disable-next-line no-unused-vars
        createTimestamp = null,
        // eslint-disable-next-line no-unused-vars
        updateTimestamp = null,
        // eslint-disable-next-line no-unused-vars
        id = null,
        ...others
      } = data
      // Establecemos todos los valores posibles (los datos dependen de cada empresa)
      this.formFields = {
        ...others
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Actualizamos activación en BD
      await updateCompanyActivationById({
        ...this.formFields,
        id: this.id
      })
      // Información resultados acción de salvado
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
      // Cerramos dialog
      this.hideDialog()
      // Emitimos evento general para que cualquier lista
      // a la escucha, recargue su listado
      this.$root.$emit('reload-list')
    },
    /**
     * Acciones para eliminar la activación de la compañía
     */
    async deleteCompanyActivation() {
      try {
        // Loading
        this.formProcessing = true
        // Eliminamos activación
        await deleteCompanyActivationById(this.id)
      } catch (error) {
        // show error
        this.handleError(error.message)
      } finally {
        // Loading
        this.formProcessing = true
      }
    },
    /**
     * Hide dialog
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    },
    /**
     * Dado el id del campo, devolvemos una etiqueta para este
     *
     * @param {string} id - UID del campo
     * @return {string} - etiqueta del campo
     */
    getFieldLabel(id = null) {
      return get(this.fieldLabels, id, 'Sin etiqueta')
    },
    /**
     * Indica el tipo de campo que debe mostrar en el formulario
     *
     * @param {string} id - UID del campo
     * @return {string} - tipo de campo
     */
    getFieldType(id = null) {
      let type = 'text'

      // Dependiendo del campo
      switch (id) {
        case 'status':
        case 'type':
          type = 'select'
          break
        case 'images':
          type = 'images'
          break
        case 'place':
          type = 'googleMapAutocomplete'
          break
        default:
          type = 'text'
      }

      return type
    },
    /**
     * Obtenemos las opciones del campo (selector)
     *
     * @param {string} id - UID del campo
     * @return {array} - listado de opciones
     */
    getFieldOptions(id = null) {
      let options = []

      switch (id) {
        case 'status':
          options = Object.values(ACTIVATION_STATUS)
          break
        case 'type':
          options = Object.values(ACTIVATION_TYPES)
          break
        default:
          options = []
      }

      return options
    },
    /**
     * Obtenemos establecimiento mediante el ID de la activación
     *
     * @param {string} id - UID de la activación
     * @returns {object} - datos del establecimiento existente
     */
    async getPlaceByCompanyActivationId(id) {
      try {
        const place = await getPlaceByCompanyActivationId(id)
        return place
      } catch {
        return null
      }
    }
  }
}
