// Utils
import { dataURItoBlob } from '@/utils'
import { isNil } from 'lodash'

export default {
  name: 'FileUploader',
  props: {
    // Support types --> https://www.w3schools.com/tags/att_input_accept.asp
    accept: {
      type: String,
      default: 'image/x-png,image/png,image/jpeg,image/jpg'
    },
    capture: {
      type: Boolean,
      default: false
    },
    maxSizeFiles: {
      type: Number,
      default: 2 // MB
    },
    multiple: {
      type: Boolean,
      default: false
    },
    enabledResizer: {
      type: Boolean,
      default: true
    },
    maxSizeImage: {
      type: Object,
      default() {
        return {
          width: 1280,
          height: 720
        }
      }
    },
    thumbnail: {
      type: Boolean,
      default: false
    },
    thumbnailSize: {
      type: Object,
      default() {
        return {
          width: 100,
          height: 100
        }
      }
    }
  },
  data() {
    return {
      processingFiles: false,
      uploadFiles: []
    }
  },
  methods: {
    /**
     * make click on input file
     */
    handleClick() {
      this.$refs.input.click()
    },
    /**
     * Handle event "changeFile"
     *
     * @param {Object} $event - object event
     */
    async handleChangeFile($event) {
      try {
        // Comienza proceso (loading)
        this.processingFiles = true
        this.$emit('onProcessingFiles', this.processingFiles)

        // Vaciamos datos anteriores
        this.uploadFiles = []

        // Con la posibilidad de que se generen thumbnails
        // duplicamos el número de ficheros a subir, indicando
        // quienes son "thumbnails" y quienes no, junto con otros atributos
        Array.from($event.target.files).forEach((file) => {
          // Fichero original
          this.uploadFiles.push({
            file,
            thumbnail: false
          })
          // Fichero thumbnail
          if (this.thumbnail) {
            this.uploadFiles.push({
              file,
              thumbnail: !isNil(this.thumbnail),
              thumbnailSize: this.thumbnailSize
            })
          }
        })

        // Si no hay ficheros, paramos aquí
        if (!Array.isArray(this.uploadFiles) || this.uploadFiles.length === 0) return

        // Check types files
        this.checkFileTypes(this.uploadFiles, this.accept)

        // Resize images
        if (this.enabledResizer || this.thumbnail) {
          this.uploadFiles = await this.resizeAllImages(this.uploadFiles, this.maxSizeImage)
        }
        // Check files size
        this.checkMaxSizeFiles(this.uploadFiles, this.maxSizeFiles)

        this.$emit('onUploadFiles', this.uploadFiles)
      } catch (error) {
        this.handleError(error)
      } finally {
        this.processingFiles = false
        this.$emit('onProcessingFiles', this.processingFiles)
      }
    },
    /**
     * Handle error event
     *
     * @param {Object} error - send error
     */
    handleError(error) {
      this.$emit('onError', error)
    },
    /**
     * Check file types
     *
     * @param {Array} files - uploading files
     * @param {string} accept - type accepted files
     */
    checkFileTypes(files, accept) {
      files.forEach((file) => {
        if (accept.indexOf(file.file.type) === -1) {
          throw new Error('Tipo de fichero no soportado')
        }
      })
    },
    /**
     * Check max size files
     *
     * @param {Array} files - uploading files
     * @param {string} maxSize - type accepted files
     */
    checkMaxSizeFiles(files, maxSize) {
      let totalSizeFiles = 0
      files.forEach((file) => {
        const fileSizeMB = file.size / 1024 / 1024

        totalSizeFiles += totalSizeFiles + fileSizeMB
        if (totalSizeFiles >= maxSize) {
          throw new Error(`Se ha excedido el tamaño máximo ${maxSize} MB de subida de ficheros`)
        }
      })
    },
    /**
     * Resize images before to upload
     *
     * @param {Array} files - uploading files
     * @param {Object} sizeImage - new size to resizer
     */
    async resizeAllImages(files, sizeImage) {
      const images = await Promise.all(
        files.map(async (file) => {
          const image = await this.resizeImage(
            file.file,
            file.thumbnail ? file.thumbnailSize : sizeImage,
            file.thumbnail
          )

          // Nombre imagen
          image.name = file.file.name
          // Es thumbnail?
          image.thumbnail = file.thumbnail

          return image
        })
      )

      return images
    },
    /**
     * Resize one image with canvas
     *
     * @param {Object} file - uploading file
     * @param {Object} sizeImage - new size to resizer
     * @param {Boolean} crop - recortar imagen
     */
    async resizeImage(file, sizeImage, crop = false) {
      if (!file.type.match(/image.*/)) {
        throw new Error('Tipo de fichero no soportado para redimensionar')
      } else {
        const maxSize = sizeImage
        const reader = new FileReader()
        const image = new Image()
        // Elementos canvas
        const { canvasResize, canvasCrop } = this.$refs
        const ctxResize = canvasResize.getContext('2d')
        const ctxCrop = canvasCrop.getContext('2d')
        // Método para redimensionar / recortar imágenes
        const resize = () => {
          let { width, height } = image

          // Calculamos el tamaño del canvas
          // según parámetros
          if (crop) {
            // Recortamos imagen. Tomamos
            // el lado más pequeño como referencia
            if (height < width) {
              if (height > maxSize.height) {
                // Referencia "height"
                width *= maxSize.height / height
                height = maxSize.height
              }
            } else if (width > maxSize.width) {
              // Referencia "width"
              height *= maxSize.width / width
              width = maxSize.width
            }
          } else {
            // Tomamos el lado más grande como referencia
            if (width > height) {
              // Referencia "width"
              if (width > maxSize.width) {
                height *= maxSize.width / width
                width = maxSize.width
              }
            } else if (height > maxSize.height) {
              // Referencia "height"
              width *= maxSize.height / height
              height = maxSize.height
            }
          }

          // 1. Tomamos el primer canvas para redimensionar
          // la imagen inicial
          canvasResize.width = width
          canvasResize.height = height
          ctxResize.transform(1, 0, 0, 1, 0, 0)
          ctxResize.drawImage(image, 0, 0, width, height)

          // 2. Si deseamos generar un thumbnail, debemos
          // recortar del canvas anterior, la parte de la imagen
          // que deseamos y pasar la imagen al segundo canvas
          if (crop) {
            const croppedImageData = ctxResize.getImageData(
              canvasResize.width > sizeImage.width ? (canvasResize.width - sizeImage.width) / 2 : 0,
              canvasResize.height > sizeImage.height
                ? (canvasResize.height - sizeImage.height) / 2
                : 0,
              sizeImage.width,
              sizeImage.height
            )
            // Tamaño del canvas
            canvasCrop.width = sizeImage.width
            canvasCrop.height = sizeImage.height

            // Insertamos imagen recortada en canvas
            ctxCrop.putImageData(croppedImageData, 0, 0)

            // Convertimos en BASE64 y luego a BLOB y devolvemos la imagen recortada
            const dataUrl = canvasCrop.toDataURL('image/jpeg')
            const fileBlob = dataURItoBlob(dataUrl)

            fileBlob.base64 = dataUrl

            return fileBlob
          }

          // Convertimos en BASE64 y luego a BLOB y devolvemos la imagen redimensionada
          const dataUrl = canvasResize.toDataURL('image/jpeg')
          const fileBlob = dataURItoBlob(dataUrl)

          fileBlob.base64 = dataUrl
          return fileBlob
        }

        return new Promise((resolve) => {
          reader.onload = (readerEvent) => {
            image.onload = () => {
              resolve(resize())
            }
            image.src = readerEvent.target.result
          }
          reader.readAsDataURL(file)
        })
      }
    }
  }
}
