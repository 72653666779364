export default {
  name: 'VuetifyPopoverMenu',
  props: {
    icon: {
      type: String,
      default: 'mdi-plus'
    },
    items: {
      type: Array,
      default() {
        return []
      },
      required: true
    },
    labelEmpty: {
      type: String,
      default: 'No hay elementos a mostrar'
    },
    /**
     * https://vuetifyjs.com/en/components/menus/#menus
     */
    menuVOptions: {
      default() {
        return {}
      },
      type: Object
    },
    showBadget: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Devuelve la clase "badget" si cumple las condiciones
     *
     * @return {String}
     */
    badgetClass() {
      return this.items.length > 0 && this.showBadget ? 'badget' : ''
    },
    /**
     * Opciones del menú que se despliega
     *
     * @return {Object}
     */
    currentMenuVOptions() {
      const defaultMenuVOptions = {
        bottom: true,
        left: true,
        'content-class': 'vuetify-popover-menu-content'
      }
      return { ...defaultMenuVOptions, ...this.menuVOptions }
    }
  },
  methods: {
    /**
     * Handle click on item
     *
     * @param {Number} index - index inside list items
     */
    handleClickMenuItem(index) {
      this.$emit('onClickMenuItem', index)
    }
  }
}
