// Storage
import { uploadFile, deleteFile, refFromUrl, getDownloadURLFromRef } from '@/services/storage'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Components
import FileUploader from '../FileUploader'
import ListImagesPreview from '../ListImagesPreview'
import VuetifyContentLoading from '../VuetifyContentLoading'
// utils
const uniqid = require('uniqid')

export default {
  name: 'CameraUploader',
  components: { ListImagesPreview, FileUploader, VuetifyContentLoading },
  mixins: [uiMixin],
  props: {
    capture: {
      type: Boolean,
      default: true
    },
    /**
     * Is enabled the resizer images?
     */
    enabledResizer: {
      type: Boolean,
      default: true
    },
    /**
     * Images to show in the list
     */
    images: {
      type: Array,
      default() {
        return []
      }
    },
    /**
     * Label to delete button
     */
    labelButtonPreview: {
      type: String,
      default: 'Vista previa'
    },
    /**
     * Label to upload button
     */
    labelButtonUpload: {
      type: String,
      default: 'Subir fotos'
    },
    /**
     * Maximum size allowed to upload a image
     */
    maxSizeImage: {
      type: Object,
      default() {
        return {
          width: 1280,
          height: 720
        }
      }
    },
    /**
     * Path (firebase storage) where the images will be save
     */
    pathToSave: {
      type: String,
      default: 'tests'
    }
  },
  data() {
    return {
      currentImages: this.images || [],
      uploaderIsBusy: false
    }
  },
  methods: {
    /**
     * Get the files that the user wants to upload
     *
     * @param {boolean} loading - there are some files in processing?
     */
    handleProcessingFiles(loading) {
      this.uploaderIsBusy = loading
    },
    /**
     * handle click on "button upload"
     */
    handleAddImage() {
      if (!this.uploaderIsBusy) {
        this.$refs.fileUploader.handleClick()
      }
    },
    /**
     * Handle remove image
     *
     * @param {number} index - index
     */
    async handleRemoveImage(index) {
      if (!this.uploaderIsBusy) {
        try {
          const image = this.currentImages[index]

          if (!image) {
            throw new Error('No existe la imagen que intentó eliminar')
          }

          this.uploaderIsBusy = true
          // Remove image from server
          await this.removeImage(image, index)
        } catch (error) {
          this.handleError(error || 'Hubo un error al intentar eliminar la imagen')
        } finally {
          this.uploaderIsBusy = false
        }
      }
    },
    /**
     * Get the files that the user wants to upload
     *
     * @param {string} error - error to show
     */
    handleError(error) {
      this.modifyAppAlert({
        type: 'error',
        text: error,
        visible: true
      })
    },
    /**
     * Get the files that the user wants to upload
     *
     * @param {array} files - files array to upload
     */
    async handleUploadFiles(files) {
      const file = files[0]
      // upload image to server
      this.addImage(file)
    },
    /**
     * Handle preview image
     *
     * @param {string} url - image url
     * @param {number} index - index inside "currentImages"
     */
    async removeImage(url, index) {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          const ref = await refFromUrl(url)
          // Remove from server
          await deleteFile(ref)
          // Remove from array
          this.currentImages.splice(index, 1)
          // Emit current images
          this.$emit('onCurrentImages', this.currentImages)
        },
        actionButtonText: 'Eliminar',
        text: '¿Desea eliminar la foto?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Add image to server (Firebase)
     *
     * @param {object} file - file BLOB
     */
    async addImage(file) {
      try {
        this.uploaderIsBusy = true

        const fileName = uniqid()
        const path = this.pathToSave || uniqid()
        const ref = await uploadFile(file, `${path}/${fileName}`)
        const url = await getDownloadURLFromRef(ref)

        // Add to current available images
        this.currentImages.push(url)
        // Emit current images
        this.$emit('onCurrentImages', this.currentImages)
      } catch (error) {
        this.handleError(error || 'Hubo un error al intentar eliminar la imagen')
      } finally {
        this.uploaderIsBusy = false
      }
    },
    /**
     * To reset images in component
     */
    onResetImages() {
      this.currentImages = []
    }
  }
}
