// Vuex
import { mapMutations } from 'vuex'
// Utils
import { get, isNil } from 'lodash'

export default {
  data() {
    return {
      formFieldsValidations: {}, // Opciones de las validaciones de los campos
      formDefaultError: 'Comprueba los errores del formulario',
      formDefaultFieldError: 'Campo no completado correctamente',
      formProcessing: false
    }
  },
  methods: {
    ...mapMutations('app', ['setAppAlert']),
    /**
     * Default method to submit form.
     * It is possible that the child view has a owner method
     */
    async onSubmit() {
      this.formProcessing = true
      try {
        // Launch function before submit formulary (optional)
        if (typeof this.beforeSubmit === 'function') {
          await this.beforeSubmit()
        }

        // Launch default submit action form
        this.actionSubmit()

        // Launch function after submit formulary (optional)
        if (typeof this.afterSubmit === 'function') {
          await this.afterSubmit()
        }

        return true // para tratar ciertos flujos
      } catch (error) {
        this.showErrorSubmit(error.message)
        return false // para tratar ciertos flujos
      } finally {
        this.formProcessing = false
      }
    },

    /**
     * Submit action
     * @return {promise}
     */
    actionSubmit() {
      if (!isNil(this.$v)) {
        this.$v.formFields.$touch()
        if (this.$v.formFields.$invalid) {
          throw new Error(this.formError || this.formDefaultError)
        }
      }
      return true
    },

    /**
     * Show error submit action
     *
     * @param {string} error - error to show
     */
    showErrorSubmit(error) {
      this.setAppAlert({
        text: error || this.formDefaultError,
        type: 'error',
        visible: true
      })
    },

    /**
     * Check the possible errors in the current field
     *
     * @param {string} field - name field in model
     * @return {null | false} Pristine or Error
     */
    checkFieldErrors(field) {
      const vField = get(this, `$v.formFields.${field}`, false)
      // Remove $each from field
      const fieldNoEach = field.replace(/\$each\.[\d{1,4}]\./i, '')
      const formFieldsValidations = get(this, `formFieldsValidations.${fieldNoEach}`, false)
      const errors = []

      if (vField && vField.$dirty && vField.$error) {
        if (formFieldsValidations) {
          Object.entries(vField)
            .filter((error) => !error[1] && formFieldsValidations[error[0]])
            .forEach((error) => {
              errors.push(formFieldsValidations[error[0]])
            })
        } else {
          errors.push(this.formDefaultFieldError)
        }
      }
      return errors
    },

    /**
     * Touch field input
     *
     * @param {string} field - name field in model
     */
    touchField(field) {
      const currentField = get(this, `$v.formFields.${field}`, false)
      if (currentField) {
        currentField.$touch()
      }
    }
  }
}
