export default {
  name: 'CardContainer',
  props: {
    title: {
      type: String,
      default: null
    },
    paddingContent: {
      type: String,
      default: ''
    },
    /**
     * https://vuetifyjs.com/en/components/sheets/
     */
    vSheetOptions: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    /**
     * Parámetros pasados al componente "v-sheet"
     *
     * @return {object}
     */
    currentVSheetOptions() {
      return {
        elevation: 1,
        rounded: true,
        ...this.vSheetOptions
      }
    },
    /**
     * Clases CSS del contenido de v-sheet
     *
     * @return {object}
     */
    cssContent() {
      return {
        'card-container__content': true,
        [this.paddingContent]: true
      }
    }
  }
}
