// Utils
import { cloneDeep, debounce, isNil } from 'lodash'

export default {
  name: 'SearchForm',
  props: {
    // Campos de consulta de inicio
    queryFields: {
      type: Array,
      default() {
        return []
      }
    },
    // Campos por los que deseamos buscar
    fields: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      // Posibles operadores de las condiciones
      operators: [
        {
          value: '==',
          label: 'igual'
        },
        {
          value: '!=',
          label: 'distinto'
        },
        {
          value: 'like',
          label: 'que empiece por'
        },
        {
          value: '<',
          label: 'menor que'
        },
        {
          value: '<=',
          label: 'menor o igual que'
        },
        {
          value: '>',
          label: 'mayor que'
        },
        {
          value: '>=',
          label: 'mayor o igual que'
        }
      ],
      // Consultas a realizar
      currentQueryFields: this.queryFields
    }
  },
  watch: {
    currentQueryFields(newValue, oldValue) {
      // Cuando alguno de los filtros es eliminado
      // volvemos a lanzar el evento "onSearch"
      if (!isNil(newValue) && !isNil(oldValue) && newValue.length < oldValue.length) {
        this.handleSearchDebounce()
      }
    }
  },
  methods: {
    /**
     * Añadimos un campo de búsqueda más
     */
    handleAddQueryField() {
      // Este cloneDeep es para que funcione el "watch" de "currentQueryFields"
      const currentQueryFields = cloneDeep(this.currentQueryFields)
      currentQueryFields.push({
        operator: this.operators[0].value,
        field: this.fields[0].field,
        type: this.fields[0].type,
        value: null
      })

      this.currentQueryFields = currentQueryFields
    },
    /**
     * Eliminamos un campo de búsqueda
     *
     * @param {number} index - indice dentro del array de condiciones
     */
    handleRemoveQueryField(index) {
      // Este cloneDeep es para que funcione el "watch" de "currentQueryFields"
      const currentQueryFields = cloneDeep(this.currentQueryFields)

      currentQueryFields.splice(index, 1)
      this.currentQueryFields = currentQueryFields
    },
    /**
     * Mostramos las campos de consulta
     */
    handleShowSearchForm() {
      // Incluimos un primer elemento en la variable "currentQueryFields"
      // para que aparezcan los filtros
      this.handleAddQueryField()
    },
    /**
     * Lanzada cuando cambiamos el campo a tratar
     *
     * @param {string} field - nombre del campo a tratar
     * @param {number} index - indice dentro del array de condiciones
     */
    handleChangeField(field, index) {
      // Cuando se modifica el primer campo (field), debemos cambiar
      // el tipo (type) y los valores (values) de la condición almacenada
      const fieldIndex = this.fields.findIndex((f) => f.field === field)

      if (!isNil(field) && fieldIndex > -1) {
        this.currentQueryFields[index].type = this.fields[fieldIndex].type
        this.currentQueryFields[index].values = this.fields[fieldIndex].values
      }
    },
    /**
     * Cuando pulsamos en el botón de "Buscar"
     *
     * @return {array} - array con las opciones seleccionadas
     */
    handleSearchDebounce: debounce(function handleSearch() {
      const selectedQueryFields = this.currentQueryFields
        .filter((queryField) => {
          return !isNil(queryField.operator) && !isNil(queryField.value) && queryField.value !== ''
        })
        .map((queryField) => {
          return {
            field: queryField.field,
            operator: queryField.operator,
            value: this.parseSearchValue(queryField.value, queryField.format)
          }
        })
      // Emitimos evento
      this.$emit('onSearch', selectedQueryFields)
    }, 150),
    /**
     * Aplica formato para realizar un correcto filtro
     *
     * @param {string} value
     * @param {string} format - string, number, boolean
     * @return {string | number | boolean}
     */
    parseSearchValue(value, format = 'string') {
      switch (format) {
        case 'string':
          value = String(value)
          break
        case 'number':
          value = Number.parseInt(value, 10)
          break
        case 'boolean':
          value = Boolean(value)
          break
        default:
          value = String(value)
      }

      return value
    }
  }
}
